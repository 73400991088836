@font-face {
  font-family: "Artex";
  src: url("../fonts/Artex-Regular.woff2") format("woff2"),
    url("../fonts/Artex-Regular.woff") format("woff"),
    url("../fonts/Artex-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Artex SemiExtended";
  src: url("./../fonts/Artex-SemiExtendedRegular.woff2") format("woff2"),
    url("./../fonts/Artex-SemiExtendedRegular.woff") format("woff"),
    url("./../fonts/Artex-SemiExtendedRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Basier Square";
  src: url("./../fonts/BasierSquare-Regular.woff2") format("woff2"),
    url("./../fonts/BasierSquare-Regular.woff") format("woff"),
    url("./../fonts/BasierSquare-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Basier Square Medium";
  src: url("./../fonts/BasierSquare-Medium.woff2") format("woff2"),
    url("./../fonts/BasierSquare-Medium.woff") format("woff"),
    url("./../fonts/BasierSquare-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HalvarBreit-Bd";
  src: url("./../fonts/HalvarBreit-Bd.woff2") format("woff2"),
    url("./../fonts/HalvarBreit-Bd.woff") format("woff"),
    url("./../fonts/HalvarBreit-Bd.ttf") format("truetype"),
    url("./../fonts/HalvarBreit-Bd.eot") format("eot");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter-Regular";
  src: url("./../fonts/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url("./../fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
/* global style */
.main-content {
  background-image: url("../media/icons/standard_icons/dashboard-pattern.svg") !important;
  background-position: 0 -5rem;
  background-attachment: fixed;
  background-size: cover;

  &-wrap .accordion .inventory-table {
    .-selected:not(.first) {
      @apply bg-white pointer-events-none;
      > .checkbox_td {
        > label {
          @apply bg-white;
          + input {
            @apply relative;
            z-index: 1;
          }
        }
      }
      .accordion-body {
        @apply pointer-events-auto #{!important}/* add any additional styles you want to exclude */;
      }
      .st_icons {
        @apply bg-white #{!important};
      }
    }
  }
}

iframe {
  max-width: 100%;
}
.page-content {
  min-height: calc(100dvh - 3.9375rem);
  // &:has(.card-detail){
  //   min-height: calc(100dvh - 7.0625rem);
  // }
  &.trade-body {
    min-height: calc(100dvh - 4rem);
  }
}

/* accordion */
// .accordion {
//   &-item {
//     min-height: calc(100vh - 61px);

// }

/* accordion */
.accordion {
  &-item {
    @apply rounded #{!important};

    &.no-rounded-b {
      @apply rounded-b-none #{!important};
    }
  }

  &-right-sticky {
    // box-shadow: -0.1875rem -0.1875rem 0.3125rem rgba(0 0 0 / 8%);
    .row-disabled {
      pointer-events: none;
      background: #f2f2f2;
      button {
        span {
          fill: #bfbfbf;
        }
      }
    }
  }
}

/* border devider */
.divide-y-2 > :not([hidden]) ~ :not([hidden]) {
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse))) !important;
}

/* tooltip */
.react-tooltip {
  opacity: 1;
  box-shadow: 0 0 0.25rem rgba(0 0 0 / 20%);
  .react-tooltip-arrow {
    @apply w-2 h-2;
  }
  &.no-angle {
    .react-tooltip-arrow {
      @apply hidden;
    }
  }
}

/* offcanvas-backdrop */
.upload-ticket-popup {
  ~ .offcanvas-backdrop {
    top: 3.875rem;
    @apply fixed left-0 h-full w-full bg-black z-30;

    &.show {
      @apply opacity-70;
    }
  }
}

.new_rep_temp {
  ~ .offcanvas-backdrop {
    top: 3.75rem;
    @apply fixed left-0 h-full w-full bg-black z-30;

    &.show {
      @apply opacity-0;
    }
  }
}

/* row colors of inventory table steps */
.row {
  &-indigo-light {
    @apply bg-indigo-500/10;

    &.-selected {
      &:not(.bulkUploaded) {
        width: 100%;
        * {
          &:not(
              td:first-child,
              button,
              button > span,
              .react-datepicker__day,
              .react-datepicker__current-month,
              .react-datepicker__day-name,
              .table_dropdown__control *,
              .table_multiDropdown__control *,
              td > label,
              td:last-child > .left-shad,
              .table_row .checkbox_td label,
              .left-shad,
              .first-col,
              .sticky-icons .price_low > *,
              .sticky-icons .price_low input,
              .sticky-icons .price_regular > *,
              .sticky-icons .price_regular input,
              .sticky-icons .price_medium > *,
              .sticky-icons .price_medium input,
              .sticky-icons .price_high > *,
              .sticky-icons .price_high input,
              .sticky-icons .proceed-price,
              .multi-select-dropdown .table_multiDropdown--is-disabled,
              .date_h.disable *,
              .td_block label,
              .fixed_price_proceed .eighteen-col,
              .lock-img-view .lock-icon,
              .priceInnerView span,
              .proceed-price,
              .ppeBlock *,
              .icon-group,
              .triangle-up
            ) {
            // @apply text-indigo-500;
            border-color: #6f6cff !important;
          }
          .disable_date {
            .table-date-icon {
              @apply border-white #{!important};
            }
            input {
              @apply text-current #{!important};
            }
          }
        }

        // input::placeholder {
        //   @apply text-indigo-500;
        // }

        input:focus {
          // @apply text-black;
        }

        select {
          background-image: url("../media/icons/standard_icons/select-angle-indigo.svg") !important;
        }

        .table-date-icon {
          @apply fill-indigo-500;
        }

        .table_dropdown__control:not(.table_dropdown__control--is-disabled),
        .table_multiDropdown__control:not(
            .table_multiDropdown__control--is-disabled
          ),
        td > div > input:not(.disable),
        .dropdown,
        .in-hand-check {
          @apply shadow-tableShadow;
        }
      }
      &.bulkUploaded {
        .proceed-price {
          .dropdown {
            @apply shadow-tableShadow;
          }
          .price_field,
          input {
            border-color: #6f6cff !important;
          }
        }
      }

      .proceed-price {
        input:disabled {
          @apply rounded-l-none #{!important};
        }
      }
    }
    &.-selected:not(.first) {
      .table_dropdown__control:not(.table_dropdown__control--is-disabled),
      .table_multiDropdown__control:not(
          .table_multiDropdown__control--is-disabled
        ),
      td > div > input:not(.disable),
      .dropdown,
      .in-hand-check {
        @apply shadow-none;
      }
      .edited {
        // @apply bg-indigo-500/20 shadow-none #{!important};
        .table_dropdown__control:not(.table_dropdown__control--is-disabled),
        .table_multiDropdown__control:not(
            .table_multiDropdown__control--is-disabled
          ),
        > div > input:not(.disable),
        .dropdown .price_field,
        .dropdown input,
        .in-hand-check,
        > input,
        :not(.disable_date) .date_h input {
          @apply bg-indigo-500/20 shadow-none #{!important};
        }
      }
      .sticky-icons {
        z-index: 1;
      }
    }
  }

  &-disabled {
    pointer-events: none;
    @apply bg-gray-100;

    > .td_block {
      @apply text-gray-400;
      &.checkbox_td {
        z-index: 2;
      }
      > label {
        @apply bg-gray-100;
        > input,
        + input {
          @apply border-gray-300 relative;
          z-index: 1;
        }
      }
      .st_icons {
        @apply bg-gray-100 #{!important};
        button {
          span {
            @apply fill-gray-400;
          }
        }
      }
    }

    input,
    .table_multiDropdown__control,
    .table_dropdown__control,
    .price_field,
    .in-hand-check,
    .react-datepicker__input-container input {
      @apply bg-gray-100 border-gray-200 text-gray-400 placeholder:text-gray-400;
    }
    .table_dropdown__control,
    .table_multiDropdown__control,
    .table-date-icon,
    .proceed-price .price_field,
    .proceed-price input {
      @apply border-gray-200 #{!important};
    }
    .table_dropdown__single-value,
    .table_multiDropdown__indicator svg,
    .dropdown .price_field,
    .table-date-icon,
    .table_dropdown__placeholder,
    input::placeholder,
    .table_multiDropdown__placeholder {
      @apply text-gray-400 fill-gray-400 #{!important};
      z-index: 1;
    }
    .table_multiDropdown__indicator,
    .in-hand-check {
      @apply bg-gray-100;
    }

    .dropdown-toggle,
    .table-date-icon {
      @apply text-violet-800/50 fill-violet-800/50;
    }
    &.ppeBlock {
      .singleMKTPrice:not(.edited) {
        .priceFieldPPE {
          .price_field,
          input {
            @apply border-gray-100 #{!important};
          }
          input {
            border-left-color: #fff !important;
          }
        }
      }
    }
  }

  &-error {
    @apply bg-rose-500/5;
  }

  &-processing {
    pointer-events: none;
    // @apply bg-yellow-500/10;

    > .td_block {
      // opacity: 0.5;
    }
  }
}

.accordion-search .searchbar-wrap {
  @apply w-full;
}

.uploaded-file-preview {
  canvas {
    @apply w-full h-full #{!important};
  }
}

/* ripple animation icon of publish live summary */
.publish-ripple-icon {
  &:before {
    animation: ripple 2.1s 0s infinite ease-in-out;
  }

  &:after {
    animation: ripple 2.1s 0.7s infinite ease-in-out;
  }

  > div {
    &:before {
      animation: ripple 2.1s 1.4s infinite ease-in-out;
    }
  }

  @keyframes ripple {
    0% {
      @apply opacity-100 h-0 w-0;
    }

    75% {
      @apply opacity-50;
    }

    100% {
      @apply opacity-0 h-5 w-5;
    }
  }
}

/* responsive */
/* mobile */
@media (max-width: 768px) {
  /* offcanvas-backdrop */
  .upload-ticket-popup {
    ~ .offcanvas-backdrop {
      top: 2.75rem;
    }
  }
}

.fade {
  opacity: 0;
  transition: opacity 1ms;
}

.fade2 {
  opacity: 0;
  transition: opacity 700ms;
}

.fade-effect {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}

.navigation_pg {
  ul {
    display: flex;
    margin-left: 0.25rem;
    .break {
      @apply hidden;
    }
    li {
      &.disabled {
        a {
          @apply pointer-events-none;
        }
        button {
          @apply pointer-events-none;
          span {
            @apply fill-gray-400;
          }
        }
      }
    }
  }
}

.details {
  svg {
    @apply max-w-full max-h-full;
  }
}

.container_login {
  @apply m-auto px-5 w-full;
  max-width: 1800px;
}

.check {
  opacity: 0;
}

// .circle_checkbox.checked{
//        .check {
//         opacity: 1;
//       }
// }
.green_block {
  .inner_block {
    @apply border-green-600/30 bg-green-600;
    --tw-bg-opacity: 7%;
  }
}

.grey_block {
  .inner_block {
    @apply border-gray-200 bg-gray-100/50;

    .card_title {
      .icon {
        span {
          @apply fill-white stroke-gray-300;
        }
      }
    }

    .bottom_card {
      @apply border-gray-200;
    }
  }
}

.red_block {
  .inner_block {
    @apply border-rose-500/20 bg-rose-500;
    --tw-bg-opacity: 7%;

    .card_title {
      .icon {
        span {
          @apply fill-rose-500 stroke-none;
        }
      }
    }

    .bottom_card {
      @apply border-rose-500/20;
    }
  }
}

.single_block.active {
  .inner_block {
    @apply shadow-3xl bg-white border-none;

    .card_title {
      .icon {
        span {
          @apply fill-gray-100 stroke-gray-400;
        }
      }
    }
  }
}

.single_accordion {
  &.success {
    @apply bg-green-600 bg-opacity-[7%] border-green-600/30;

    .acc_content {
      @apply border-green-600/30;
    }
  }
}

.contact_popup {
  .popup_header {
    .tix_logo {
      svg {
        .a {
          @apply hidden;

          @screen md {
            @apply block;
          }
        }
      }
    }
  }
}

.detail_login {
  .login_header {
    @apply fixed top-0 left-0 w-full z-30;

    svg {
      g {
        .a {
          @apply hidden;

          @screen md {
            @apply block;
          }
        }
      }
    }

    + .content_block {
      @apply pt-24;

      @screen md {
        @apply pt-36;
      }
    }
  }

  .right_panel {
    .mob_stripe {
      @apply fixed bottom-0 left-0 w-full z-30;
    }

    .inner_right {
      @media (max-width: 767px) {
        transform: translateY(100%);
        position: fixed;
        top: 4.125rem;
        left: 0;
        width: 100%;
        height: calc(100vh - 4.0625rem);
        overflow: auto;
        background: #fff;
        z-index: 20;
        padding: 0;
        transition: all 0.3s;
      }
    }

    &.active {
      .inner_right {
        transform: translateY(0);
      }
    }
  }
}

.all-dropdown-errorField > .all_dropdown__control {
  border: 1px solid red !important;
}

// DELETE FOR FILE UPLOAD IN SIGNUP PAGE
.row-enter {
  opacity: 0;
}

.row-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

.row-exit {
  opacity: 1;
}

.row-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.zoom-dd {
  .all_dropdown__control {
    @apply w-full min-w-full border-none bg-transparent h-6 rounded-l #{!important};
    .all_dropdown__value-container {
      padding: 0;
      padding-left: 7px !important;
    }

    &:hover {
      @apply border-none;
    }

    .all_dropdown__indicator {
      @apply w-2.5;
    }

    .all_dropdown__placeholder {
      @apply p-0 #{!important};
    }

    &--menu-is-open {
      @apply bg-indigo-500 rounded-r-none #{!important};

      .all_dropdown__placeholder,
      .all_dropdown__single-value {
        @apply text-white;
      }

      .all_dropdown__indicator {
        background-image: url("../media/icons/standard_icons/up-angle-white.svg") !important;
      }
    }
  }
}

.tx-pay-module {
  .btn {
    @apply border border-gray-300 text-violet-800 bg-white transition font-medium rounded text-sm13 px-2.5 py-0.5;

    &:hover {
      @apply border-indigo-500 text-white bg-indigo-500;
    }

    &.green-btn {
      @extend .btn;
      @apply border-green-600 text-white bg-green-600;

      &:hover,
      &:active {
        @apply bg-white text-green-600;
      }
    }

    &.gray-btn {
      @extend .btn;
      @apply inline-flex items-center bg-gray-100 border-none;

      svg {
        @apply fill-violet-500;
      }

      &:hover {
        @apply text-white bg-indigo-500;

        svg {
          @apply fill-white;
        }
      }
    }
  }
}

/* Equal Height */
.equal-td-height {
  .equal-height-JlocK {
    @apply flex flex-col justify-around;
  }
}

.page_footer {
  position: fixed !important;
  left: 0;
  width: 100%;
  @media (min-width: 768px) {
    width: calc(100% - 3.75rem);
    left: 3.75rem;
    &.menu_open {
      width: calc(100% - 13.875rem);
      left: 13.875rem;
      transition: all 0.2s;
    }
  }
}

.bot_spacing {
  @media (min-width: 991px) {
    margin-bottom: 3.25rem;
  }
}

// .bottom_acc {
//   transition: none !important;
// }
.h-fourty > .all_dropdown__control {
  height: 40px !important;
}
.whiteFormFields {
  .asterisk {
    @apply hidden;
  }
}
.new-inp {
  input[type="checkbox"] {
    + label {
      @apply text-sm14;
    }
  }
  label:not(.circle_checkbox) {
    @apply text-sm11 px-1 rounded-none bg-white;
  }
  // .checkbox_label {
  //   label {
  //     @apply rounded;
  //   }
  // }

  .peer:placeholder-shown ~ .peer-placeholder-shown\:text-xs {
    @apply text-sm14 px-[.1875rem] bg-transparent;
  }

  .peer:focus ~ .peer-focus\:text-xxs {
    @apply text-sm11 px-1 bg-white;
  }

  input:not([type="checkbox"], [type="radio"], .all_dropdown__input) {
    height: 2.5rem;
    font-size: 0.875rem;
  }
  input:not([type="checkbox"], [type="radio"], .all_dropdown__input) {
    .whiteFormFields & {
      padding-left: 0.9375rem;
      padding-right: 0.9375rem;
    }
  }
  input.peer {
    @apply bg-white;

    &:placeholder-shown {
      @apply bg-gray-100/50;
    }
    .whiteFormFields & {
      &:placeholder-shown {
        @apply bg-white;
        &:focus {
          background: rgba(111, 108, 255, 0.07);
        }
      }
    }

    &:focus {
      background: rgba(111, 108, 255, 0.07);
    }
  }

  .all_dropdown__control {
    height: 2.5rem !important;
  }

  .all_dropdown__value-container.all_dropdown__value-container--has-value
    .all_dropdown__placeholder {
    top: -1.1875rem;
  }
  .login-dd {
    .all_dropdown__value-container .all_dropdown__placeholder {
      font-size: 0.875rem !important;
      font-family: "Basier Square", "sans-serif";
    }
    .all_dropdown__value-container.all_dropdown__value-container--has-value
      .all_dropdown__placeholder {
      font-size: 0.625rem !important;
    }
  }

  input {
    &.border-rose-500 {
      &:placeholder-shown {
        background: #fff;
      }
    }
  }
}

.inner-inp {
  label {
    @apply text-xxs px-1 rounded-none bg-white;
  }

  .peer:placeholder-shown ~ .peer-placeholder-shown\:text-xs {
    @apply text-sm12 px-[3px] bg-transparent;
  }

  .peer:focus ~ .peer-focus\:text-xxs {
    @apply text-xxs px-1 bg-white;
  }

  input:not([type="checkbox"], [type="radio"], .all_dropdown__input) {
    height: 2.5rem;
    font-size: 0.75rem;
  }

  input.peer,
  textarea {
    @apply bg-white;

    &:placeholder-shown {
      @apply bg-gray-100/50;
    }

    &:focus {
      background: rgba(111, 108, 255, 0.07);
    }
  }

  .all_dropdown__control {
    height: 2.5rem !important;
    .smallDropdown & {
      height: 1.875rem !important;
    }
  }

  .all_dropdown__value-container.all_dropdown__value-container--has-value
    .all_dropdown__placeholder {
    top: -1.1875rem;
  }

  .all_dropdown__placeholder {
    color: #0d0019;
  }

  input {
    &.border-rose-500 {
      &:placeholder-shown {
        background: #fff;
      }
    }
  }

  textarea {
    &.border-rose-500 {
      background: #fff;
    }
  }
}

.form_login,
.bas-font {
  input,
  label {
    @apply font-basier;
  }
}

.loader-stripe {
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 5px;
    background: #00e500;
    transition: none;
  }

  &.loading,
  &.loading:hover {
    background: #350066;
    border-color: #350066;

    &::before {
      width: 100%;
      transition: all 1.5s;
    }
  }
}

.react-tooltip {
  &.styles-module_show__2NboJ {
    @apply opacity-100 text-xxs #{!important};
    line-height: 0.875rem !important;
  }
}

.title-accordion {
  @apply bg-violet-700 transition-all cursor-pointer;
  &.active {
    @apply bg-violet-600;
    .mkt-btn {
      .arrow {
        span {
          @apply bg-violet-600;
        }
      }
    }
  }
  // &:not([data-te-collapse-collapsed]) {
  //   @apply bg-violet-500;
  // }
}
.acc-click {
  &:not([data-te-collapse-collapsed]) {
    ~ .title-accordion {
      @apply bg-rose-500;
    }
  }
}

.ellips-text {
  @apply text-ellipsis overflow-hidden whitespace-nowrap;

  p {
    @apply text-ellipsis overflow-hidden;
  }
}

.no-scroll {
  .infinite-scroll-component {
    overflow: unset !important;
  }
}

.order-13 {
  order: 13;
}

.order-14 {
  order: 14;
}

.order-15 {
  order: 15;
}

.order-16 {
  order: 16;
}

.date-cus {
  // .picker-input:placeholder-shown ~ .picker-input-placeholder-shown\:text-xs{
  //   @apply bg-transparent;
  // }
  // .picker-input:focus ~ .picker-input-focus\:text-xxs{
  //   @apply bg-white;
  // }
  .table-date-icon {
    svg {
      position: relative;
      z-index: 1;
    }
  }

  .picker-input {
    // @apply bg-gray-100/50;
    &:placeholder-shown {
      @apply bg-gray-100/50;
    }

    &:focus {
      background: rgba(111, 108, 255, 0.07);
    }
  }

  // .all_dropdown__control{
  //   height: 40px !important;
  // }
  // .all_dropdown__value-container.all_dropdown__value-container--has-value .all_dropdown__placeholder{
  //   top: -1.1875rem;
  // }
}

.req-icon-block.new-inp
  .table-date-icon
  + .peer:placeholder-shown
  ~ .peer-placeholder-shown\:text-xs,
.req-icon-block.new-inp
  .peer:placeholder-shown
  ~ .peer-placeholder-shown\:text-xs {
  font-size: 12px;
}
.req-icon-block.new-inp .peer:focus ~ .peer-focus\:text-xxs {
  font-size: 10px;
}
.req-icon-block.new-inp .table-date-icon + .peer:focus ~ .peer-focus\:text-xxs {
  font-size: 10px;
  left: 48px !important;
}
.req-icon-block.new-inp .table-date-icon + .peer ~ label {
  left: 48px;
}
.req-icon-block {
  &.new-inp {
    input {
      font-size: 12px;
    }
  }
  .table-date-icon {
    left: 1px;
    top: 1px;
    right: 1px;
    bottom: 1px;
    height: auto !important;
    background: #fff;
    z-index: 1;
  }

  .group.dp-focused .group-\[\.dp-focused\]\:\!fill-white {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100% !important;
  }
}

.overlay-40 {
  @apply relative;

  &:after {
    @apply content-[''] absolute top-0 left-0 w-full h-full bg-white/40 z-0;
  }

  span {
    @apply z-10 relative;
  }
}

.error-msg {
  @apply absolute -bottom-2 text-xs bg-white px-[.1875rem] leading-[.9375rem] left-[1.375rem] rounded-sm;
}

/**********mobile menu********/
@media (max-width: 767px) {
  .notification_class {
    .menu-wrap {
      @apply bg-transparent p-0 overflow-visible;
      height: 100dvh;

      .logo {
        width: 60px;
        transition: all 0.2s;
        @apply py-[.9375rem] px-[.8125rem] bg-violet-600;
      }

      ul:not(.submenu) {
        @apply absolute;
        top: 3.8125rem;
        left: -3.75rem;
        width: 3.75rem;
        height: calc(100% - 61px);
        padding: 16px 10px 0 10px;
        transition: all 0.2s;
        z-index: 1;

        // &:before {
        //   content: "";
        //   position: absolute;
        //   left: 0;
        //   top: 0;
        //   width: 60px;
        //   height: 100%;
        //   background: #350066;
        //   z-index: -1;
        //   transition: all 0.3s;
        // }

        &.active-menu {
          // transform: translateX(0);
          left: 0;
        }

        a {
          &:not(:focus):hover {
            > li {
              background-color: #6f6cff;
              pointer-events: all;
              // min-width: 150px;

              > span {
                display: block;
              }
            }
          }
          &[title="Notifications"] {
            li {
              min-width: 40px;
              &:hover {
                background: #6f6cff;
              }
              &.active:hover {
                min-width: 40px;
              }
            }
          }

          li {
            // min-width: 150px;

            // pointer-events: none;
            &.active {
              min-width: 40px;

              &:hover {
                // min-width: 150px;
              }
            }
          }
        }

        &:not(.active-menu) {
          li {
            min-width: 40px !important;
          }
        }
      }

      &.open-menu {
        .logo {
          background: #350066;
          transition: all 0.2s;
          width: 11.6875rem;
        }
        .menu-ul {
          width: 11.6875rem;

          &:before {
            width: 11.6875rem;
          }
        }
      }
    }
  }
}

/* Slider */

.head-carousel li.slide.selected {
  z-index: 3 !important;
  overflow: hidden;
}

.head-carousel .carousel-slider li a {
  z-index: 1 !important;
  position: relative;
}

.head-carousel .carousel-slider li p {
  overflow: hidden;
}

.head-carousel .carousel-slider li p span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.head-carousel .carousel-root {
  overflow: hidden;
}

.form_multiDropDown__menu {
  z-index: 20 !important;
}

.left-shad {
  position: relative;
  &:before {
    @apply content-[''] absolute -left-2 top-0 w-2 h-full bg-gradient-to-r from-black/0 to-black/5;
  }
}

.checkbox_td {
  position: relative;
  &:after {
    @apply content-[''] absolute -right-2 top-0 w-2 h-full bg-gradient-to-r to-black/0 from-black/5;
  }
}

.modal-overlay {
  z-index: 1045;
}

/* TX TRADE MAP*/
.map-container svg {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.inner_single_package {
  > * {
    position: relative;
    z-index: 1;
  }
  @apply relative;
  &:after {
    @apply content-[''] absolute top-0 left-0 w-full h-full bg-indigo-500 bg-opacity-[7%] opacity-0 z-0 transition-all;
  }
  &:hover {
    &:after {
      @apply opacity-100;
    }
  }
}
// .map-svg {
//   svg {
//     g {
//       &.lowest{
//         .block{
//           @apply fill-gray-100;
//         }
//       }
//       &.highest{
//         &.dark{
//           .block{
//             &.oppac-tag {
//               @apply fill-white;
//             }
//           }
//         }
//       }
//       .block {
//         @apply fill-gray-650/40 stroke-violet-800/10 transition-all duration-200;
//       }
//       .dark {
//         .block {
//           @apply fill-indigo-500/40;
//         }
//         &:hover {
//           .block {
//             @apply fill-indigo-500;
//           }
//           .section-label {
//             @apply fill-white;
//           }
//         }
//       }
//       &.light-dark{
//         .block{
//           @apply fill-map-purple-100;
//         }
//         &.hover{
//           .block{
//             @apply fill-map-purple-900;
//           }
//         }
//         .dark{
//           .block{
//             @apply fill-map-purple-500;
//           }
//         }
//       }
//       &.hover,
//       &.colored {
//         .block {
//           @apply fill-map-purple-900 #{!important};
//         }
//         .section-label {
//           @apply fill-white;
//         }
//         &:hover {
//           .section-label {
//             @apply fill-white;
//           }
//         }
//       }
//     }
//   }
// }
.map-svg {
  svg {
    g {
      &.middle {
        .section-label {
          @apply fill-transparent stroke-transparent;
        }
      }
      &.lowest {
        .block {
          @apply fill-gray-100;
        }
      }
      &.highest {
        &.dark {
          .block {
            &.oppac-tag {
              @apply fill-white;
            }
          }
        }
        .st0 {
          @apply fill-gray-100 stroke-black/10;
        }
      }
      .block {
        @apply fill-transparent stroke-violet-800/10 transition-all duration-200;
        &.selected-block {
          @apply fill-map-purple-500;
        }
        &.error-block {
          @apply fill-rose-500/60;
        }
      }
      .dark {
        .block {
          @apply fill-map-purple-500;
        }
        &:hover {
          .block {
            @apply fill-map-purple-100;
          }
          .section-label {
            @apply fill-white;
          }
        }
      }

      &.light-dark {
        .block {
          @apply fill-map-purple-100;
        }
        &.hover {
          .block {
            @apply fill-map-purple-900;
          }
        }
        .dark {
          .block {
            @apply fill-map-purple-500;
          }
        }
      }
      &.transparent {
        .block {
          @apply fill-transparent;
          &.oppac-tag {
            @apply fill-transparent #{!important};
          }
        }
      }
      &.hover,
      &.colored {
        @apply fill-white #{!important};

        .block {
          @apply fill-map-purple-900 #{!important};
        }
        .transparent {
          .block {
            @apply fill-map-purple-900;
            &.oppac-tag {
              @apply fill-transparent #{!important};
            }
            + .section-label {
              @apply fill-white text-white #{!important};
            }
          }
        }
        .section-label {
          @apply fill-white #{!important};
        }
        &:hover {
          .section-label {
            @apply fill-white;
          }
        }
      }
    }
  }
}

.notification-list {
  ul {
    li {
      strong {
        @apply font-medium;
      }
    }
  }
}

.ba_item {
  .ba_dd {
    @apply md:min-w-[15rem] min-w-[12.1875rem] z-30;
  }
}

.event-search-block {
  .input-field {
    .table-date-icon {
      @apply bg-transparent fill-gray-500 #{!important};
    }
    .picker-input {
      @apply pl-[2.5rem] text-sm15 text-gray-500 #{!important};
    }
    .group.dp-focused {
      .table-date-icon {
        @apply fill-gray-500 #{!important};
      }
    }
  }
}
.search-panel-event {
  .input-field {
    .date_h {
      .table-date-icon {
        left: 0.0625rem;
        top: 0.0625rem;
        bottom: 0.0625rem;
        height: auto;
        width: 1.75rem;
      }
      &:hover {
        .dropdownArrow {
          span {
            @apply fill-indigo-500;
          }
        }
      }
      &:hover,
      &:focus,
      &.dp-focused {
        .table-date-icon {
          @apply rounded-l bg-white #{!important};
          span {
            @apply border-indigo-500 fill-indigo-500;
          }
        }
        .dropdownArrow {
          span {
            @apply fill-indigo-500;
          }
        }
        .react-datepicker__input-container {
          input {
            @apply border-indigo-500;
            &::placeholder {
              @apply text-indigo-500;
            }
          }
        }
      }
    }
    .table-date-icon {
      @apply fill-violet-800 #{!important};
    }
    .picker-input {
      @apply bg-violet-100 bg-opacity-[7%] text-violet-800 text-sm13 h-[1.875rem] #{!important};
    }

    input:hover,
    input:focus {
      + span {
        @apply border-indigo-500 fill-indigo-500 bg-white rounded-l;
      }
    }
    .all_dropdown__control {
      background-color: rgba(111, 108, 255, 0.07);
      &:hover,
      &.all_dropdown__control--is-focused.all_dropdown__control--menu-is-open {
        .ddIcon {
          @apply border-indigo-500 bg-white rounded-l;
          span {
            @apply border-indigo-500 fill-indigo-500;
          }
        }
      }
      .all_dropdown__single-value {
        @apply text-sm13 text-violet-800 #{!important};
      }
      .all_dropdown__placeholder {
        span {
          @apply text-sm13 text-gray-400 pl-0.5;
        }
      }
    }
    .dark-placeholder {
      .all_dropdown__placeholder {
        span {
          @apply text-violet-800;
        }
      }
    }
    // :not(.dark-placeholder){
    //   .all_dropdown__placeholder {
    //     span {
    //       @apply text-sm13 text-gray-400;
    //     }
    //   }
    // }
    .all_dropdown__menu-list {
      @apply p-2;
      .all_dropdown__option {
        @apply border mb-2.5 border-gray-200 rounded;
        &:hover {
          border-color: rgba(111, 108, 255, 0.2);
        }
      }
    }
    .all_dropdown__menu {
      .all_dropdown__option {
        @apply px-2 py-[.1875rem] text-sm12 #{!important};
        &.all_dropdown__option--is-selected {
          @apply text-violet-800 border-indigo-500;
          &:hover {
            @apply bg-white;
          }
        }
      }
    }
  }
}

/* TX PAY*/
#withdrowFunds .react-datepicker-wrapper {
  width: 100%;
}
.bottom-arrow {
  // &:after {
  //   content: "";
  //   height: 0.5625rem;
  //   width: 0.5625rem;
  //   background-color: #00d900;
  //   position: absolute;
  //   left: 50%;
  //   top: 100%;
  //   transform: translate(-50%, -50%) rotate(45deg);
  //   display: none;
  // }
  &:after {
    content: "";
    position: absolute;
    left: -0.3125rem;
    bottom: 0;
    right: -0.3125rem;
    // border-bottom: 1px solid #e6e6e6;
    display: none;
  }
  &:not([data-te-collapse-collapsed]) {
    &::after {
      display: block;
    }
    .mkt-btn {
      .arrow {
        display: block;
      }
    }
  }
}

.custom-trade-tooltip {
  position: absolute;
  background-color: #fff;
  color: #0d0019;
  padding: 6px;
  font-size: 12px;
  border-radius: 4px;
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}
.custom-header_tooltip {
  color: #6f6cff;
}
.custom-desc-tooltip {
  text-align: center;
}
.custom-trade-tooltip::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fff transparent;
}

.custom-trade-tooltip {
  padding: 0;
  transform: translateY(10px);
  &::after {
    border-width: 0 5px 5px 5px;
    bottom: -5px;
  }
  P {
    font-size: 10px;
    line-height: 12px;
    padding: 4px 5px;
  }
  .custom-header_tooltip {
    display: block;
    border-bottom: 1px solid #e6e6e6;
  }
}
.inp-number {
  input {
    @apply absolute top-0 left-0 w-full h-full p-0 border-0;
  }
  &:after {
    content: attr(data-value) " ";
    visibility: hidden;
    white-space: pre;
    grid-area: 1/2;
    font: inherit;
    min-width: 2px;
    border: 0;
    margin: 0;
    outline: 0;
    padding: 0;
  }
}

.report_box {
  .with-acco:first-child {
    > .title {
      @apply hidden;
    }
    .acc_box {
      @apply flex;
    }
  }
}

.react-datepicker__input-container,
.date_h {
  &:hover {
    input,
    .table-date-icon {
      @apply border-indigo-500;
    }
  }
}
input:not([disabled]):not(:disabled),
input:not(.\!bg-gray-100):not([disabled]):not(:disabled),
.dropdown,
:not(.dropdown) input:not([disabled]):not(:disabled) {
  &:hover {
    @apply border-indigo-500;
  }
  &.green-check {
    &:hover {
      @apply border-green-500;
    }
  }
}
.proceed-price {
  .dropdown {
    @apply rounded;
  }
  .price_field,
  input {
    @apply border-gray-500;
  }
  .price_high {
    .price_field,
    input {
      @apply border-rose-500 #{!important};
    }
    .price_field {
      @apply text-rose-500 #{!important};
    }
    input {
      @apply bg-rose-500/5;
      &:hover {
        + label {
          @apply text-rose-500;
        }
      }
    }
    label {
      @apply text-rose-500 #{!important};
    }
    &:hover {
      .price_field {
        @apply text-rose-500;
      }
    }
  }

  .price_medium {
    .price_field,
    input {
      @apply border-orange-500 #{!important};
    }
    .price_field {
      @apply text-orange-500 #{!important};
    }
    input {
      @apply bg-orange-500/5;
      &:hover {
        + label {
          @apply text-orange-500;
        }
      }
    }
    label {
      @apply text-orange-500 #{!important};
    }
    &:hover {
      .price_field {
        @apply text-orange-500;
      }
    }
  }

  .price_regular {
    .price_field,
    input {
      @apply border-green-600 #{!important};
    }
    .price_field {
      @apply text-green-600 #{!important};
    }
    input {
      @apply bg-green-600/5;
      &:hover {
        + label {
          @apply text-green-600;
        }
      }
    }
    label {
      @apply text-green-600 #{!important};
    }
    &:hover {
      .price_field {
        @apply text-green-600;
      }
    }
  }

  .price_low {
    .price_field,
    input {
      @apply border-sky-blue-500 #{!important};
    }
    .price_field {
      @apply text-sky-blue-500 #{!important};
    }
    input {
      @apply bg-sky-blue-500/5;
      &:hover {
        + label {
          @apply text-sky-blue-500;
        }
      }
    }
    label {
      @apply text-sky-blue-500 #{!important};
    }
    &:hover {
      .price_field {
        @apply text-sky-blue-500;
      }
    }
  }
}

.dropdown {
  &:hover {
    .price_field {
      @apply text-indigo-500;
    }
    .price_field,
    input {
      @apply border-indigo-500;
      &::placeholder {
        color: #6f6cff !important;
      }
    }
  }
}
.form_multiDropDown__control {
  &:hover {
    @apply border-indigo-500 #{!important};
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade3 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade4 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade5 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade6 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade7 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade8 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade9 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade10 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade11 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade12 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade13 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade14 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes anime-fade-out {
  100% {
    opacity: 0;
  }
  0% {
    opacity: 1;
  }
}
.anim-fade {
  animation: fade 1s forwards;
}
.anim-fade1 {
  animation: fade1 1s forwards;
}
.anim-fade2 {
  animation: fade2 1s forwards;
}
.anim-fade3 {
  animation: fade3 1s forwards;
}
.anim-fade4 {
  animation: fade4 1s forwards;
}
.anim-fade5 {
  animation: fade5 1s forwards;
}
.anim-fade6 {
  animation: fade6 1s forwards;
}
.anim-fade7 {
  animation: fade7 1s forwards;
}

.anim-fade8 {
  animation: fade8 1s forwards;
}
.anim-fade9 {
  animation: fade9 1s forwards;
}
.anim-fade10 {
  animation: fade10 1s forwards;
}
.anim-fade11 {
  animation: fade11 1s forwards;
}
.anim-fade12 {
  animation: fade12 1s forwards;
}
.anim-fade13 {
  animation: fade13 1s forwards;
}
.anim-fade14 {
  animation: fade14 1s forwards;
}

$total-children: 500; // Set the total number of child elements

// @for $i from 1 through $total-children {
//   .anime-fade-out:nth-child(#{$i}) {
//     /* Your child element styles */

//     /* Apply animation delay based on the loop index */
//     animation: anime-fade-out
//       2s
//       ease-in-out
//       0s; // Adjust the animation duration and timing function as needed
//   }
// }
.anime-fade-out {
  /* Your child element styles */

  /* Apply animation delay based on the loop index */
  animation: anime-fade-out 2s ease-in-out 0s; // Adjust the animation duration and timing function as needed
}

.inventory_select__control {
  .search_btn.cross_btn {
    @media (max-width: 480px) {
      top: 0px;
      left: 0px;
      height: 1.875rem;
    }
  }
}

.unpinned {
  & ~ .pinned {
    .inner-pin {
      border-bottom: 1px solid rgb(14, 165, 233);
    }
  }
}
.pinned + .unpinned {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: -1px;
    right: 0;
    width: calc(100% - 40px);
    height: 1px;
    background: #0dcaff;
    margin: auto;
  }
}

.inner-searchBox-width {
  position: relative;
}
.searchBox-width {
  position: relative;
}

.searchBox-width .input-field {
  width: 100%;
  @media (min-width: 768px) {
    max-width: 27rem;
  }
}
.suggestion {
  position: absolute;
  width: 100%;
  top: 52px;
  max-height: 27rem;
  overflow-y: scroll;
  // max-width: calc(100% - 2.5rem);
  left: 0;
  right: 0;
  margin: auto;
  @media (min-width: 768px) {
    max-width: 27rem;
  }
}
.inner-searchBox-width .suggestion {
  width: 100%;
}
.addtional_file_drag {
  width: 88%;
}

.sb_menus {
  @media (max-width: 1200px) {
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  }
}

tr.legacy_block {
  &.assign {
    td {
      &:nth-last-child(2) {
        padding-right: 6.25rem;
      }
    }
  }
  td {
    &:last-child {
      // @apply pr-[5.3125rem];
      padding-right: 5.3125rem;
    }
  }
}

.checkbox_td {
  @apply relative;
  &:before {
    @apply content-[''] absolute left-0 top-0 w-full h-full bg-white -z-10;
  }
}

.inv_table {
  thead {
    tr {
      th {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -1px;
          width: 100%;
          height: 1px;
          background: #e6e6e6;
        }
      }
    }
  }
  tr:not(.error_tr) {
    th {
      background-color: #fff;
      &:last-child {
        position: sticky !important;
        right: 0;
        background: #fff;
      }
    }
    td:last-child {
      position: sticky !important;
      right: 0;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: -1;
      }
    }
  }
  // tr:not(.error-row, .row-error) {
  //   &:hover {
  //     @apply bg-indigo-500 bg-opacity-[7%];
  //     td.checkbox_td {
  //       label {
  //         @apply bg-indigo-500 bg-opacity-[7%];
  //       }
  //     }
  //     .st_icons {
  //       @apply bg-indigo-500 bg-opacity-[7%];
  //     }
  //   }
  // }

  .tableBody {
    [data-test-id="virtuoso-item-list"] {
      > div:last-child {
        .first_row.addinventoryRow,
        .second_row {
          border-bottom-width: 0px;
        }
        .ppeBlock {
          @apply border-t;
        }
      }
    }
  }
}

#tableId1 {
  tbody {
    // overflow: auto !important;
    // max-height: 200px !important;
    // height: 200px;
  }
  > div {
    // overflow: unset !important;
    // height: auto !important;
    // max-height: 200px;
    will-change: transform;
    @apply scrollbar-none;
    > div {
      // position: relative !important;
      will-change: transform;
      // overflow: unset !important;
      // @apply scrollbar-none;
      // @apply scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full;
      &::-webkit-scrollbar {
        width: 0.3125rem;
        height: 0.3125rem;
      }
    }
  }
  .inv_table {
    > .tableBody {
      @apply scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full;
      // max-height: calc(100vh - 20.375rem);
      &::-webkit-scrollbar {
        width: 0.3125rem;
        height: 0.3125rem;
      }
    }
  }
}

.ff-big-arrow {
  @-moz-document url-prefix() {
    min-width: 8.0625rem !important;
  }
}
#tableId1.noscroll-x {
  > div {
    > div {
      @apply scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full;
      &::-webkit-scrollbar {
        width: 4px;
        height: 0px !important;
      }
      &.tableHead {
        @apply scrollbar-none;
      }
      &.no-scroll {
        @apply scrollbar-none relative;
        z-index: 1;
      }
    }
  }
}
.collapse-icon {
  padding: 10px !important;
  @apply w-10 h-10 justify-center;
}

.inv_table tr:not([class]):first-child td::after {
  align-items: flex-end;
  padding-bottom: 20px;
}
.inv_table tr:not([class]) td::after {
  content: "Loading..." !important;
  background: #f2f2f2 !important;
  min-width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  font-size: 13px;
  // background-image: url(../media/icons/other_icons/process.svg);
  // background-repeat: no-repeat;
  // background-size: 15px;
}
// .inv_table tr:not([class]) td::before {
//   content: "Loading" !important;
//   background: #f00 !important;
//   min-width: 20vw;
//   height: 100%;
//   animation: bg-anim 2s infinite;
// }
// @keyframes bg-anim {
//   0% {
//     transform: translateX(0);
//   }
//   100% {
//     transform: translateX(100%);
//   }
// }

.icon_td {
  > div {
    @apply absolute left-0 top-0 w-full flex items-center justify-center border-b;
    height: calc(100% + 1px);
  }
  &::after {
    @apply content-[''] absolute left-0 top-0 w-full h-full bg-white -z-10;
  }
  &.no-icon_h {
    > div {
      height: calc(100% + 0px);
    }
  }
}

.vir_table,
.tablehover {
  tbody {
    tr {
      &:hover {
        background-color: rgba(111, 108, 255, 0.07);
        .icon_td {
          > div {
            background-color: rgba(111, 108, 255, 0.07);
          }
        }
      }
    }
  }
}

.inv_table tr:not([class]) td {
  width: 100% !important;
  position: sticky !important;
  // position: absolute;
  left: 0;
  // top: 0;
  // right: 0;
  // bottom: 0;
  // margin: auto;
}

.inv_table {
  overflow-y: auto;
  background: #f2f2f2;
  .tableHead {
  }
}
.tableBody {
  // background: #f2f2f2;
  // &:before {
  //   content: "";
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   background: #f00;
  // }

  > div {
    // position: relative !important;
    width: auto !important;
    min-width: 100%;
  }
  [data-test-id="virtuoso-item-list"] {
    position: relative;
    > div {
      // display: flex;
      background: #fff;
      min-width: 100%;
      &:last-child {
        > div {
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
    // &:before {
    //   content: "";
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   width: 100%;
    //   height: 100%;
    //   background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    //   border-radius: 5px;
    //   background-size: 200% 100%;
    //   animation: 1.5s shine linear infinite;
    // }
  }
}
// .loader {
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   background: #f2f2f2;
//   z-index: 0;
//   &:before {
//     content: "";
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 100%;
//     height: 100%;
//     background: linear-gradient(90deg, #f00 8%, #fff 18%, #f00 33%);
//     border-radius: 5px;
//     background-size: 200% 100%;
//     animation: 1.5s shine linear infinite;
//   }
// }

// @keyframes shine {
//   to {
//     background-position-x: -200%;
//   }
// }

.table_row .react-datepicker-wrapper input {
  height: 1.5rem;
}

.per_button {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 100%;
    width: 100%;
    height: 8px;
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.05) 100%);
  }
}

.attendee-form {
  input {
    height: 30px !important;
  }
}

.trade-loader-stripe-wrap {
  .loader-stripe {
    // height: 8px;
    // width: 0;
    &:before {
      @apply bg-green-600 h-2;
    }

    &.loading {
      background: transparent;
      &:before {
        transition: linear 7s;
        width: 100%;
      }
    }
  }
}
.shar-right {
  &:after {
    @apply content-[''] absolute -right-2 top-0 w-2 h-full bg-gradient-to-l from-black/0 to-black/5;
  }
}

// STICKET EVENT IN INVENTORY
.stickyEventBar {
  top: 70px;
  position: sticky;
  z-index: 1;
  margin-bottom: 20px;
  @apply shadow-md;
}
.sales-table,
.tx-trade-table {
  tbody {
    tr {
      &:hover {
        background-color: rgba(111, 108, 255, 0.07);
        .icon_td {
          > div {
            background-color: rgba(111, 108, 255, 0.07);
          }
        }
      }
    }
  }
}
.highlight {
  border-top: 10px solid !important;
  border-bottom: 10px solid !important;
  border-color: transparent !important;
}

.abc {
  min-width: 1920px !important;
  width: 100% !important;
  display: block;
}

.shimmer-effect {
  // background-color: #f6f7f8;

  position: relative;
  background: #f2f2f2 !important;
  overflow: hidden;
  border: none;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    background-image: linear-gradient(
      -45deg,
      #f2f2f2 25%,
      rgba(242, 242, 242, 0.2) 50%,
      #f2f2f2 75%
    );
    // background-repeat: no-repeat;
    background-size: cover;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
    background-repeat: repeat-x;
  }

  * {
    opacity: 0;
    pointer-events: none;
  }
}
@keyframes placeholderShimmer {
  0% {
    // background-position: -100% 0;
    transform: translateX(-100%);
  }

  100% {
    // background-position: 100% 0;
    transform: translateX(100%);
  }
}

.icon_td {
  &::after {
    @apply content-[''] absolute left-0 top-0 w-full h-full bg-white -z-10;
  }
  &.no_white_bg {
    &::after {
      @apply bg-transparent;
    }
  }
}

.vir_table {
  tbody {
    tr {
      &:hover {
        background-color: rgba(111, 108, 255, 0.07);
        .icon_td {
          > div {
            background-color: rgba(111, 108, 255, 0.07);
          }
        }
      }
    }
  }
}

.dashboard-shimmer .shimmer-title {
  display: none;
}

// loader box event first time
.page-loader {
  .loader-strip {
    @apply relative overflow-hidden;
    .load-line {
      @apply absolute left-0 top-0 w-0 h-full bg-green-600;
      content: "";
      &.new-line {
        animation: loaderAnim 3s linear forwards;
      }
    }
  }
}

@keyframes loaderAnim {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

// .topFilters {
//   &.sticky {
//     &:hover {
//       top: 3.75rem !important;
//       transition: all 0.3s;
//     }
//   }
// }
// .topFilters {
//   &.sticky {
//     #headingOne {
//       .collapse-icon {
//         display: none;
//       }
//     }
//     &.inv_topfilter {
//       #inventoryaccordion {
//         display: none;
//       }
//     }
//   }
// }

.hover-style2 {
  thead {
    tr {
      // background: #fff;
    }
  }
  tbody {
    tr {
      // background: #fff;
      &:hover,
      &.matchView {
        background-color: #fff;
        td {
          background-color: rgba(111, 108, 255, 0.07);
          &.icon_td {
            > div {
              background-color: rgba(111, 108, 255, 0.07);
            }
          }
        }
      }
    }
  }
}

.inventory-main [data-test-id] {
  display: flex;
  flex-wrap: wrap;
}
.lock-icon img {
  width: 1.125rem;
  height: 1.125rem;
}

.lock-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.lock_form input {
  background: white;
}

// HIDE ERROR
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.selectAllStripe {
  transition: all 0.7s;
  position: sticky !important;
  top: 17.125rem;
  @media (max-width: 767px) {
    top: 14.5rem;
  }
  &.active {
    height: 2.5625rem;
    @apply border-b;
  }
}

.bulk-action {
  .selectAllStripe {
    top: 22.625rem;
  }
}

body {
  &.hover-outside {
    overflow-y: auto;
  }
}
.td-dd-auto-width .dropdown_wrap {
  max-width: none;
}
#tableScroll {
  position: relative;
  // overflow: hidden;
  overflow: unset;
  // &.highlightBoth {
  //   &::before {
  //     content: "";
  //     position: absolute;
  //     left: 0;
  //     top: 1.875rem;
  //     width: 100%;
  //     height: 10px;
  //     z-index: 1;
  //     box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2);
  //   }
  //   &::after {
  //     content: "";
  //     position: absolute;
  //     left: 0;
  //     width: 100%;
  //     height: 10px;
  //     z-index: 1;
  //     box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2);
  //     top: inherit;
  //     bottom: -10px;
  //   }
  // }

  &.NohighlightBottom {
    &::after {
      display: none !important;
    }
  }
  &.NohighlightTop {
    &::before {
      display: none !important;
    }
  }
  .tableHead {
    position: relative;
    z-index: 1;
  }
}
.dateplaceholder {
  top: -0.4375rem;
  left: 2.3125rem;
  position: absolute;
  z-index: 1;
  margin: 0px;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 0.1875rem;
  padding-right: 0.1875rem;
  font-size: 0.625rem;
  --tw-text-opacity: 1;
  color: rgb(191 191 191 / var(--tw-text-opacity));
}
.date_h:hover .dateplaceholder {
  color: #6f6cff;
}

.row-hold-new {
  &:hover {
    @apply bg-sky-blue-300;
  }
}

.second_row {
  .error-box {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      z-index: -1;
    }
  }
}
.row-error-new,
.row-error-new:hover {
  .first_row,
  .ppeBlock,
  .PPEViewFields {
    background-color: rgba(250, 0, 75, 0.07) !important;

    .checkbox_td label,
    .st_icons.row-error-new {
      background-color: rgba(250, 0, 75, 0.07) !important;
    }
    + div.second_row {
      .error-box {
        background-color: rgba(250, 0, 75, 0.07);
      }
    }
  }
}

.edited-new {
  border: none;
  @apply bg-indigo-500/20 shadow-none #{!important};
}

.lock-img-view {
  @apply flex items-center justify-center sticky left-0 p-0;
  width: 2.5rem;
  min-width: 2.5rem;
  max-width: 2.5rem;
  z-index: 1;
  height: 2.5rem;

  .lock-icon {
    img {
      width: 0.8125rem;
      height: 0.9375rem;
    }
  }
  .unlock {
    img {
      width: 0.8125rem;
      height: 0.8125rem;
    }
  }
}
.row-hold-new,
.row-hold-new:hover {
  .ppeBlock,
  .PPEViewFields,
  .first_row {
    background-color: #edfbff !important;

    .checkbox_td label,
    .st_icons.row-hold-new {
      background-color: #edfbff !important;
    }
    + div.second_row {
      .error-box {
        background-color: #edfbff !important;
      }
    }
  }
}
.row-processing-new,
.row-processing-new:hover {
  .first_row {
    background-color: rgba(194, 120, 3, 0.07) !important;

    .checkbox_td label,
    .st_icons.row-processing {
      background-color: rgba(194, 120, 3, 0.07) !important;
    }
    + div.second_row {
      .error-box {
        background-color: rgba(194, 120, 3, 0.07);
      }
    }
  }
}

/*Minified css*/
.cancel-view {
  @apply flex mx-2 items-center justify-center h-6 w-6 text-white bg-white border border-gray-300 transition rounded;
}
.upload-view {
  @apply flex mx-2 items-center justify-center h-6 w-6 text-white bg-violet-500 rounded #{!important};
}
.upload-img-view {
  @apply flex flex-auto items-center justify-center p-1  w-10 border-r;
  max-width: 2.5rem;
  height: 2.5rem;
}
.status-view {
  @apply relative flex flex-auto items-center justify-center p-1 w-10;
  height: 2.5rem;
}

.column-view {
  &:hover {
    background: rgba(111, 108, 255, 0.07);
  }
  .disable {
    > .inner_dis {
      @apply bg-gray-100 h-6 text-xs py-0 px-2.5 flex items-center rounded;
      // min-width: 100px;
    }
  }
  .DropDown {
    @apply min-w-[6.875rem] h-6 border rounded flex items-center px-2.5 py-0.5 transition-all duration-100 relative;
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 18px;
      height: 100%;
      background-image: url("../media/icons/standard_icons/down-angle.svg") !important;
      background-repeat: no-repeat;
      background-position: left center;
    }
    &:hover {
      @apply border-indigo-500 text-indigo-500;
    }
    &.angle16 {
      &:after {
        width: 16px;
      }
    }
  }
  .delivery-type {
    min-width: 7.875rem;
  }
  .box {
    @apply h-6 border rounded flex items-center px-2.5 py-0.5 transition-all duration-100 text-gray-500;
    &:hover {
      @apply border-indigo-500 text-indigo-500;
    }
    &.disable {
      @apply bg-gray-100 border-0 text-gray-400;
    }
    &.enable {
      @apply text-violet-800;
    }
  }
  .price_box {
    max-width: 6.875rem;
    overflow: hidden;
    transition: all 0.1s;
    .icon {
      @apply absolute left-0 top-0 flex items-center justify-center w-[1.875rem] h-full  text-sm14 border-r transition-all duration-100;
    }
    .value {
      @apply pl-[1.875rem] flex-1 overflow-hidden;
    }
    &.proceed-price {
      @apply border-violet-800;
      .icon {
        @apply border-violet-800;
      }
      &:hover {
        @apply border-indigo-500 #{!important};
        .icon {
          @apply border-indigo-500 text-indigo-500 #{!important};
        }
      }
    }
    &:hover {
      .icon {
        @apply border-indigo-500 text-indigo-500 #{!important};
      }
    }
    // &.calender {
    //   .icon {
    //     .hover-img {
    //       display: none;
    //     }
    //   }
    //   &:hover {
    //     .icon {
    //       img {
    //         display: none;
    //       }
    //       .hover-img {
    //         display: block;
    //       }
    //     }
    //   }
    // }
  }
  .inhand {
    .hand-box {
      @apply px-4 cursor-pointer py-1 border rounded text-center h-6 flex items-center justify-center bg-white;
      .hand-check {
        @apply w-3 h-3 border rounded-sm overflow-hidden;
      }
    }
    &.hand {
      .hand-check {
        @apply bg-indigo-500 border-none bg-checktick bg-[length:0.5rem] bg-no-repeat bg-center #{!important};
      }
    }
    &:hover {
      .hand-check {
        @apply border-indigo-500;
      }
    }
  }
}

.column-view {
  @apply flex columns-1 border-b whitespace-nowrap items-center;
  height: 41px;
  .first-col {
    @apply bg-inherit text-center sticky left-0 p-0  items-center justify-center flex;
    z-index: 3;
    height: 2.5rem;
    min-width: 2.5rem;
    max-width: 2.5rem;
    input {
      @apply w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0 relative;
      &:checked {
        @apply bg-indigo-500 border-0;
      }
    }
  }
  .zero-col {
    & + .first-col {
      @apply left-10;
    }
  }
  .second-col {
    @apply p-1.5;
    min-width: 7.5625rem;
    max-width: 7.5625rem;
  }
  .third-col {
    @apply p-1.5 overflow-hidden whitespace-nowrap text-ellipsis;
    min-width: 7.625rem;
    max-width: 7.625rem;
  }
  .four-col {
    @apply p-1.5;
    min-width: 7.875rem;
    max-width: 7.875rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .five-col {
    @apply p-1.5;
    min-width: 7.625rem;
    max-width: 7.625rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .six-col {
    @apply p-1.5;
    min-width: 7.625rem;
    max-width: 7.625rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .seven-col {
    @apply p-1.5;
    min-width: 7.625rem;
    max-width: 7.625rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .eight-col {
    @apply p-1.5;
    min-width: 4.8125rem;
    max-width: 4.8125rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .nine-col {
    @apply p-1.5;
    min-width: 4.1875rem;
    .box {
      @apply text-violet-800;
    }
  }
  .ten-col {
    @apply p-1.5 overflow-hidden whitespace-nowrap text-ellipsis;
    min-width: 7.625rem;
    max-width: 7.625rem;
    .DropDown {
      max-width: 6.875rem;
    }
  }
  .eleven-col {
    @apply p-1.5;
  }
  .twelve-col {
    @apply p-1.5;
    min-width: 7.625rem;
    max-width: 7.625rem;
  }
  .thirteen-col {
    @apply p-1.5;
    min-width: 13.25rem;
    max-width: 13.25rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media (max-width: 767px) {
      min-width: 11.25rem;
      max-width: 11.25rem;
    }
  }
  .fourteen-col {
    @apply p-1.5;
    min-width: 13.25rem;
    max-width: 13.25rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media (max-width: 767px) {
      min-width: 11.25rem;
      max-width: 11.25rem;
    }
  }
  .fifteen-col {
    @apply p-1.5;
    min-width: 7.625rem;
    max-width: 7.625rem;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
  }
  .sixteen-col {
    @apply p-1.5;
    min-width: 7.625rem;
    max-width: 7.625rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .seventeen-col {
    @apply p-1.5;
    min-width: 7.625rem;
    max-width: 7.625rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .eighteen-col {
    @apply p-1.5;
    min-width: 7.625rem;
    max-width: 7.625rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .nineteen-col {
    @apply p-1.5;
    min-width: 3.625rem;
  }
  .twenty-col {
    @apply p-1.5;
    min-width: 8.25rem;
    .price_box {
      position: relative;
      max-width: 7.5rem !important;
      .icon {
        @apply w-3 h-3;
      }
    }
  }
  .twentyone-col {
    @apply p-1.5;
    min-width: 13.25rem;
    .DropDown {
      &.active {
        @apply text-violet-800;
      }
      &.deactive {
        @apply text-gray-500;
      }
    }
  }
  .twentytwo-col {
    @apply p-1.5;
    min-width: 13.25rem;
    .DropDown {
      &.active {
        @apply text-violet-800;
      }
      &.deactive {
        @apply text-gray-500;
      }
    }
  }
  .twentythree-col {
    @apply p-0 z-[1] md:sticky relative right-0 ml-auto bg-white;
    min-width: 14rem;
    max-width: 14rem;
    .accordion-body {
      @apply relative inline-flex items-center w-full justify-center rounded-none border-l;
      padding: 0.4375rem 0;
      height: 2.5rem;
      &.list-loading {
        @apply pointer-events-none opacity-40;
      }
    }
    .st_icons {
      @apply bg-white border-l;
      .icon-wrap {
        @apply flex;
        z-index: 1;
      }
    }
  }
}
.inventory-main {
  .table_dropdown,
  .table_multiDropdown {
    &--is-disabled {
      border: 1px solid #f2f2f2;
      border-radius: 0.25rem;
    }
  }
  input {
    &:disabled {
      border: 1px solid #f2f2f2;
      border-radius: 0.25rem;
    }
  }
}

.column-view {
  .second-col {
    padding-left: 1.5625rem;
    // padding-top: 0.5rem;
    line-height: 0.9375rem;
  }
  .third-col {
    // padding-left: 1.0625rem;
    padding-left: calc(0.375rem + 11px);
  }
  .four-col {
    padding-left: calc(0.375rem + 11px);
  }
  //this one is local, national, international delivery prices col->5,6,7
  .five-col {
    // padding-top: 0.5rem;
    padding-left: 1.25rem;
    &:not(.disabled) {
      padding-left: 1.0625rem;
      @apply text-violet-800;
    }
    &.disable {
      @apply text-gray-400;
    }
  }
  .eight-col {
    padding-left: 1.0625rem;
    // padding-top: 0.5rem;
    line-height: 0.9375rem;
  }
  .nine-col {
    padding-left: 1.0625rem;
    // padding-top: 0.5rem;
    line-height: 0.9375rem;
  }
  .ten-col {
    // padding-left: 1.0625rem;
    padding-left: calc(0.375rem + 11px);
  }
  .eleven-col {
    // padding-left: 1.0625rem; //when sell in multiple
    padding-left: 1.0625rem; //when no sell in multiple
    // padding-top: 0.5rem;
    line-height: 0.9375rem;
    min-width: 7.625rem !important;
  }
  .twelve-col {
    padding-left: 1.0625rem;
    // padding-top: 0.5rem;
    line-height: 0.9375rem;
  }
  .thirteen-col {
    padding-left: calc(0.375rem + 11px);
  }
  .fourteen-col {
    padding-left: calc(0.375rem + 11px);
  }
  .fifteen-col {
    padding-left: 1.0625rem;
    // padding-top: 0.5rem;
    line-height: 0.9375rem;
  }
  .sixteen-col {
    padding-left: 1.0625rem;
    // padding-top: 0.5rem;
    line-height: 0.9375rem;
  }
  .seventeen-col {
    // display: flex;
    // align-items: center;
    line-height: 1.1875rem;
    // display: flex;
    &::before {
      // padding-top: 0.5rem;
      content: attr(data-sybmol-attribute);
      // padding-top: 0.375rem;
      font-size: 0.875rem;
      padding-left: 0.6875rem;
      padding-right: 1.0625rem;
      line-height: 1.125rem;
      top: 0.0313rem;
      position: relative;
    }
  }
  .eighteen-col {
    // display: flex;
    // align-items: center;
    line-height: 1.1875rem;
    // display: inline-flex;
    &::before {
      // padding-top: 0.5rem;
      // content: "£";
      content: attr(data-sybmol-attribute);
      // padding-top: 0.375rem;
      font-size: 0.875rem;
      padding-left: 0.6875rem;
      padding-right: 1.0625rem;
      line-height: 1.125rem;
      top: 0.0313rem;
      position: relative;
    }
  }
  .nineteen-col {
    padding-left: 1.4375rem;
    &::before {
      padding-bottom: 0.375rem;
      content: "";
      display: inline-block;
      width: 0.75rem;
      height: 0.75rem;
      border: 0.0625rem solid rgb(217 217 217);
      border-radius: 0.0625rem;
      background-color: rgb(242 242 242);
      vertical-align: middle;
      position: relative;
      border-radius: 2px;
    }
    &:not(.nohand) {
      &::before {
        background-image: url("../media/icons/other_icons/check-tick.png");
        background-color: #6f6cff;
        background-repeat: no-repeat;
        background-position: center;
        border: 0.0625rem solid #6f6cff;
        background-size: 0.5rem;
      }
    }
  }
  .twenty-col {
    // padding-left: 2.875rem;
    line-height: 1.1875rem;
  }
  .twentyone-col {
    padding-left: calc(0.375rem + 11px);
  }
  .twentytwo-col {
    padding-left: calc(0.375rem + 11px);
  }
}
.column-view.delivery-type-exist {
  .eight-col {
    padding-left: 1.0625rem;
  }
  .nine-col {
    padding-left: 1.0625rem;
  }
  .ten-col {
    // padding-left: 1.3125rem;
  }
  .eleven-col {
    padding-left: 1.0625rem;
  }
  .twelve-col {
    padding-left: 1.0625rem;
  }
  .thirteen-col {
    // padding-left: 1.3125rem;
    padding-left: calc(0.375rem + 11px);
  }
  .fourteen-col {
    // padding-left: 1.3125rem;
    padding-left: calc(0.375rem + 11px);
  }
  .fifteen-col {
    padding-left: 1.0625rem;
  }
  .sixteen-col {
    padding-left: 1.0625rem;
  }
  .seventeen-col {
    &::before {
      // padding-left: 0.9375rem;
    }
  }
  .eighteen-col {
    &::before {
      // padding-left: 0.9375rem;
    }
  }
  .nineteen-col {
    padding-left: 1.4375rem;
  }
  .twenty-col {
    padding-left: 2.9375rem;
  }
  .twentyone-col {
    // padding-left: 1.2937rem;
    padding-left: calc(0.375rem + 11px);
  }
  .twentytwo-col {
    // padding-left: 1.2937rem;
    padding-left: calc(0.375rem + 11px);
  }
}

/* scroll design except firefox */
// body.inventory-page:not(.mac)::-webkit-scrollbar {
//   width: 8px;
// }

// body.inventory-page:not(.mac)::-webkit-scrollbar-track {
//   background: #f2f2f2;
// }

// body.inventory-page:not(.mac)::-webkit-scrollbar-thumb {
//   background: #bdbdbd;
//   border-radius: 10px;
// }

// body.inventory-page:not(.mac)::-webkit-scrollbar-thumb:hover {
//   background: #6e6e6e;
// }

// body.inventory-page:not(.mac).body-padding {
//   padding-right: 8px;
// }

// body.inventory-page:not(.mac).body-padding-17 {
//   padding-right: 17px;
// }

.scrollbar-thumb-violet-800 {
  --scrollbar-thumb: rgba(191, 191, 191, 0.5) !important;
}
.below-hidden-events {
  ~ #table-shrink {
    display: none;
  }
}

.inventory-list {
  .event__header {
    position: sticky;
    // top: 12.0625rem;
    z-index: 11;
    overflow: hidden;
    // @media (max-width: 767px) {
    //   top: 3.75rem !important;
    // }
  }
  .tableHead {
    position: sticky !important;
    // top: 14.5625rem;
    z-index: 1 !important;
  }
  // &.bulk-action {
  //   .event__header {
  //     top: 17.5625rem;
  //   }
  //   .tableHead {
  //     top: 20rem;
  //   }
  // }
}

.inventory-list {
  .mktins {
    position: sticky;
    top: 14.375rem;
    background: #fff;
    z-index: 1;
  }
  &.marketInsight-open {
    .tableHead {
      top: 24.5rem;
    }
  }
}
.sales-report-sticky-head {
  &.sticky-header-scroll {
    top: 15rem;
  }
}

.dd_block {
  .input_inner {
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 18px;
      height: 100%;
      background-image: url("../media/icons/standard_icons/down-angle.svg") !important;
      background-repeat: no-repeat;
      background-position: left center;
    }
  }
}

.all_dropdown__option {
  display: flex !important;
}

.disable_field {
  .table-date-icon {
    z-index: 1;
    @apply bg-gray-100 border-white;
    svg {
      @apply fill-gray-400;
    }
  }
  .dateplaceholder {
    @apply text-gray-400;
  }
  .react-datepicker-wrapper {
    input {
      @apply border-gray-100 text-gray-400 bg-gray-100;
      &::placeholder {
        @apply text-gray-400;
      }
    }
  }
  &.singleFOrmBLock {
    pointer-events: none;
    .table-date-icon {
      svg {
        @apply fill-violet-800;
      }
    }
    .react-datepicker-wrapper {
      input {
        @apply border-gray-100 text-violet-800 bg-gray-100;
        &::placeholder {
          @apply text-violet-800;
        }
      }
    }
  }
}
.upload_keyboard {
  &.disable_field {
    @apply border-gray-100 bg-gray-100 text-gray-400;
    .icon_block {
      @apply border-white;
      svg {
        @apply fill-gray-400;
      }
    }
  }
}

.inhand_keyboard {
  &.disable_field {
    > label {
      @apply border-gray-100 bg-gray-100 text-gray-400 pointer-events-none;
      input[type="checkbox"] {
        // background: transparent;

        @apply border-gray-400 bg-gray-100;
      }
      .icon_block {
        @apply border-white;
        svg {
          @apply fill-gray-400;
        }
      }
      .form-check-label {
        @apply text-gray-400;
        svg {
          @apply fill-gray-400;
        }
      }
    }
  }
}

.react-tooltip.\!text-xs {
  font-size: 0.625rem !important;
  line-height: 1.3 !important;
  color: #0d0019;
}
.price_suggation {
  &.single_row {
    padding-top: 0.4375rem;
    padding-left: 7.5625rem;
    right: unset;
    top: -0.4375rem;
    bottom: -0.4375rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 19.25rem;
  }
}
.inventory-table {
  [data-index]:first-child {
    .addinv_price {
      .price_suggation {
        padding-top: 0.4375rem;
        padding-left: 7.5625rem;
        right: unset;
        top: -0.4375rem;
        bottom: -0.4375rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 18rem;
      }
    }
  }
}

.table_row {
  .proceed-price {
    &.addinv_price {
      .dropdown {
        @apply shadow-none;
        // .price_field,
        // input {
        //   @apply border-gray-300;
        // }
      }
    }
  }
  &:hover {
    .proceed-price {
      &.addinv_price:not(.disable) {
        .dropdown {
          // @apply shadow-op1;
          .price_field,
          input {
            @apply border-gray-500;
          }
          &:hover {
            .price_field,
            input {
              @apply border-indigo-500;
            }
          }
        }
      }
    }
  }
}

.listing_quantity_dd {
  .all_dropdown__menu {
    .all_dropdown__option {
      &:nth-child(5) {
        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          border-bottom: 1px solid #e6e6e6;
        }
      }
    }
  }
}

// .table-meter.visibility-case1 {
//   .s_block {
//     &:first-child {
//       @apply bg-green-600;
//     }
//   }
// }
// .table-meter.visibility-case2 {
//   .s_block {
//     &:first-child,
//     &:nth-child(2) {
//       @apply bg-green-600;
//     }
//   }
// }
// .table-meter.visibility-case3 {
//   .s_block {
//     &:first-child,
//     &:nth-child(2),
//     &:nth-child(3) {
//       @apply bg-green-600;
//     }
//   }
// }
// .table-meter.visibility-case4 {
//   .s_block {
//     &:first-child,
//     &:nth-child(2),
//     &:nth-child(3),
//     &:nth-child(4) {
//       @apply bg-green-600;
//     }
//   }
// }

.table-meter.visibility-case1 {
  &.price_high {
    .s_block {
      &:first-child {
        @apply bg-rose-500 #{!important};
      }
    }
  }
  &.price_low {
    .s_block {
      &:first-child {
        @apply bg-sky-blue-500 #{!important};
      }
    }
  }
  &.price_with_empty {
    .s_block {
      &:first-child {
        @apply bg-indigo-500 #{!important};
      }
    }
  }
  &.price_regular {
    .s_block {
      &:first-child {
        @apply bg-green-600 #{!important};
      }
    }
  }
  &.price_medium {
    .s_block {
      &:first-child {
        @apply bg-orange-500 #{!important};
      }
    }
  }
  .s_block {
    &:first-child {
      @apply bg-green-600;
    }
  }
}

.table-meter.visibility-case2 {
  &.price_high {
    .s_block {
      &:first-child,
      &:nth-child(2) {
        @apply bg-rose-500 #{!important};
      }
    }
  }
  &.price_low {
    .s_block {
      &:first-child,
      &:nth-child(2) {
        @apply bg-sky-blue-500 #{!important};
      }
    }
  }
  &.price_with_empty {
    .s_block {
      &:first-child,
      &:nth-child(2) {
        @apply bg-indigo-500 #{!important};
      }
    }
  }
  &.price_regular {
    .s_block {
      &:first-child,
      &:nth-child(2) {
        @apply bg-green-600 #{!important};
      }
    }
  }
  &.price_medium {
    .s_block {
      &:first-child,
      &:nth-child(2) {
        @apply bg-orange-500 #{!important};
      }
    }
  }
  .s_block {
    &:first-child,
    &:nth-child(2) {
      @apply bg-green-600;
    }
  }
}
.table-meter.visibility-case3 {
  &.price_high {
    .s_block {
      &:first-child,
      &:nth-child(2),
      &:nth-child(3) {
        @apply bg-rose-500 #{!important};
      }
    }
  }
  &.price_low {
    .s_block {
      &:first-child,
      &:nth-child(2),
      &:nth-child(3) {
        @apply bg-sky-blue-500 #{!important};
      }
    }
  }
  &.price_with_empty {
    .s_block {
      &:first-child,
      &:nth-child(2),
      &:nth-child(3) {
        @apply bg-indigo-500 #{!important};
      }
    }
  }
  &.price_regular {
    .s_block {
      &:first-child,
      &:nth-child(2),
      &:nth-child(3) {
        @apply bg-green-600 #{!important};
      }
    }
  }
  &.price_medium {
    .s_block {
      &:first-child,
      &:nth-child(2),
      &:nth-child(3) {
        @apply bg-orange-500 #{!important};
      }
    }
  }
  .s_block {
    &:first-child,
    &:nth-child(2),
    &:nth-child(3) {
      @apply bg-green-600;
    }
  }
}
.table-meter.visibility-case4 {
  &.price_high {
    .s_block {
      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        @apply bg-rose-500 #{!important};
      }
    }
  }
  &.price_low {
    .s_block {
      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        @apply bg-sky-blue-500 #{!important};
      }
    }
  }
  &.price_with_empty {
    .s_block {
      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        @apply bg-indigo-500 #{!important};
      }
    }
  }
  &.price_regular {
    .s_block {
      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        @apply bg-green-600 #{!important};
      }
    }
  }
  &.price_medium {
    .s_block {
      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        @apply bg-orange-500 #{!important};
      }
    }
  }
  .s_block {
    &:first-child,
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      @apply bg-green-600;
    }
  }
}

.right_body {
  .block_wrap {
    .dropdown {
      &:after {
        content: "";
        position: absolute;
        bottom: 0.875rem;

        right: 0.625rem;
        width: 18px;
        height: 1.25rem;
        background-image: url("../media/icons/standard_icons/down-angle.svg") !important;
        background-repeat: no-repeat;
        background-position: left center;
      }
      &.open {
        &:after {
          background-image: url("../media/icons/standard_icons/up-angle.svg") !important;
        }
      }
    }
  }
}

.single_radio {
  input {
    &[type="radio"] {
      appearance: none;
      width: 0;
      height: 0;
      position: absolute;
      z-index: -999;
      opacity: 0;
    }
    + label {
      position: relative;
      padding-left: 1.25rem;
      cursor: pointer;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0.25rem;
        width: 0.8125rem;
        height: 0.8125rem;

        border: 0.0625rem solid #41007f;
        border-radius: 50%;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0.1775rem;
        top: 0.4375rem;
        width: 0.4375rem;
        height: 0.4375rem;
        background: #6f6cff;
        border-radius: 50%;
        opacity: 0;
        visibility: hidden;
      }
    }
    &:checked {
      + label {
        &:before {
          border-color: #6f6cff;
        }
        &:after {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.invoice-loader {
  .loader-stripe {
    &::before {
      animation: loaderAnim 13s linear forwards;
      height: 0.5rem;
    }
  }
}

.user_tooltip {
  .name {
    @apply text-sm13 leading-4;
    margin-bottom: 0.1875rem;
  }
  .type {
    @apply text-xxs text-gray-500 block;
  }
}

.after_border {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 0.625rem;
    right: 0.625rem;
    bottom: 0;
    border-bottom: 1px solid #ededed;
  }
}

.invoice-table,
.tx-trade-table {
  > div {
    @apply scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full #{!important};
    // // @apply scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full #{!important};
    scrollbar-width: unset !important;

    &::-webkit-scrollbar {
      width: 6px !important;
      height: 0px !important;
    }
  }
  thead {
    th {
      &:not(:last-child) {
        position: relative;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: #ededed;
      }
    }
  }
}

.panelty-input {
  input:focus {
    background-color: #fff;
  }
}
.panelty-status {
  .panelty-note {
    .all_dropdown--is-disabled {
      .all_dropdown__control--is-disabled {
        @apply border-gray-100/50 bg-gray-100/50 #{!important};
        .all_dropdown__single-value--is-disabled {
          @apply text-violet-800 #{!important};
        }
      }
      .all_dropdown__indicator {
        background-image: none !important;
      }
    }
  }
}

.draft_invoice_lazyload {
  position: absolute;
  width: 100%;
  bottom: -12px;
  left: -5px;
  background: white;
}

.popupinput-style2 {
  .attendee-form {
    .label_text {
      @apply text-gray-400;
    }

    input {
      @apply bg-gray-100/50 h-6 #{!important};
      &:focus {
        @apply bg-white #{!important};
      }
      &.input_focus {
        @apply border-gray-300;
      }
      + .focusIcon {
        display: none;
      }
    }
    .referralPopUpDatePicker {
      input {
        height: 1.875rem !important;
      }
    }
  }
}

.border_th {
  th {
    &:not(:last-child) {
      position: relative;
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: #e6e6e6;
    }
  }
}
table {
  th {
    &.near_arrow {
      button {
        span {
          right: unset;
          margin-left: 0.9375rem;
        }
      }
    }
  }
}

.last-auto {
  thead {
    th {
      &:first-child:not(#checkbox_shad) {
        padding-left: 1.25rem;
      }
      &:nth-last-child(2) {
        width: auto;
        button {
          span {
            right: unset;
            margin-left: 0.9375rem;
          }
        }
      }
      .proceed_price_title {
        th {
          padding-left: 0.75rem !important;
        }
      }
    }
  }
  tbody {
    td {
      &:first-child:not(#checkbox_shad) {
        padding-left: 1.25rem;
      }
      // &:nth-last-child(2) {
      //   span{
      //     p{
      //     width: auto;
      //   }
      //   }
      // }
    }
  }
  &.with-sticky {
    tbody {
      td {
        &:nth-last-child(2) {
          width: auto;

          span {
            p {
              width: auto;
            }
          }
        }
      }
    }
  }
}

.hover-style2 {
  tbody {
    tr.no-result-row {
      &:hover {
        td {
          @apply bg-gray-100/50;
        }
      }
    }
  }
}

.payout_pop,
.accordion-payout-table {
  > div {
    @apply scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full scrollbar-thin overflow-auto;
    scrollbar-width: unset;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    > div {
      > table {
        width: 100%;
      }
    }
  }
}

// New sign up form
.stepPagination {
  @apply relative;
  &::after {
    content: "";
    position: absolute;
    left: -3rem;
    bottom: -3rem;
    right: -3rem;
    top: -3rem;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, #fff 100%);
    z-index: -1;
    pointer-events: none;
    @apply max-lg:left-0 max-lg:right-0 max-lg:top-0 max-lg:bottom-0;
  }
}

.steprow {
  $steps: 6; // Total number of steps
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #00e500;
    border-radius: 0.3125rem 0 0 0.3125rem;
    width: 0%;
    transition: 0.5s;
  }
  @for $i from 1 through $steps {
    &.step#{$i} {
      &:after {
        width: percentage($i / $steps);
      }
    }
    @if $i == $steps {
      &.step#{$i} {
        &:after {
          border-radius: 0.3125rem;
        }
      }
    }
  }
}
.woot-widget-bubble {
  position: fixed !important;
  top: 0.3rem;
  right: 11rem !important;
  // width: 50px !important;
  // height: 50px !important;
  border-radius: 50px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  box-shadow: unset !important;
  z-index: -1;
  background-color: #00e500 !important;
  visibility: hidden;
}
.woot-widget-bubble:hover {
  box-shadow: unset !important;
}
body {
  background-color: inherit !important;
}
.chat-btn-css {
  // background-color: #00e500 !important;
  // width: 7.9375rem;
  // height: 1.875rem;
  // color: #ffffff;
  border-radius: 50px !important;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  // padding: 0 11px;
  // gap: 0 8px;
  // font-size: 13px !important;
  // margin-right: 10px;
}
.chat-btn-css-cros {
  border-radius: 100%;
}
.woot-widget-holder {
  border-radius: 15px !important;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1) !important;
}

.woot-widget-holder.woot-elements--right {
  top: 3.5rem;
  right: 11rem !important;
}

.category_box_wrap > div:not(.search_box):hover {
  background-color: rgba(111, 108, 255, 0.07);
  .right_cat_block {
    .info_box {
      @apply bg-white;
      // h5 {
      //   @apply text-indigo-500;
      // }
    }
  }
}

.leftDisable {
  #leftArrow {
    @apply pointer-events-none;
    span {
      @apply fill-gray-400 pointer-events-none;
    }
  }
}

.dropdown {
  &-icon {
    &::after {
      content: "";
      display: inline-block;
      width: 0.75rem;
      height: 0.3125rem;
      background-image: url("../media/icons/standard_icons/down-angle1.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-left: 0.625rem;
    }
  }

  &:hover {
    .dropdown-icon {
      @apply border-r-indigo-500;
      // &::after{
      //   transform: scale(-1);
      // }
    }
  }
  .dropdown-icon.show {
    &::after {
      transform: scale(-1);
    }
  }
  .dropdown-menu.show {
    background: #fff;
  }
}
.rightDisable {
  #rightArrow {
    @apply pointer-events-none;
    span {
      @apply fill-gray-400 pointer-events-none;
    }
  }
}

.rightDisable.leftDisable {
  .arrow_buttons {
    @apply shadow-none;
  }
  #leftArrow,
  #rightArrow {
    opacity: 0;
    visibility: hidden;
  }
}

.headShad {
  &:before {
    @apply from-black/5 bg-gradient-to-b to-black/0;
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 0.5rem;
  }
}
#sticky-shadow {
  @apply sticky bg-gradient-to-b from-black/5 to-black/0;
  width: 100%;
  height: 0.5rem;
}
.btn-disabled {
  @apply pointer-events-none bg-gray-100 text-gray-400 border-gray-100 [&_path]:stroke-gray-400 [&_line]:stroke-gray-400 #{!important};
}

.inventory_dashboard:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 1.24rem;
  background: #fff;
  z-index: 3;
}

.card-svg-logo svg path {
  fill: white;
}

.fill-card-100 {
  fill: #5020a6;
}

.fill-card-200 {
  fill: #269926;
}

.fill-card-300 {
  fill: #4f6df0;
}

.fill-card-400 {
  fill: #1fa683;
}

.fill-card-500 {
  fill: #8f60e6;
}
.subscription_drop {
  .all_dropdown__control {
    width: 100% !important;
    min-width: 100% !important;
  }
}
.fullWidthDropdown {
  .all_dropdown__control {
    width: 100% !important;
    min-width: 100% !important;
  }
}
.subscription_drop {
  .all_dropdown__menu {
    width: 100% !important;
    min-width: 100% !important;
  }
}
.active-user {
  color: rgb(0 217 0);
}
.decline-user {
  color: rgb(249 0 75);
}

.add-inventory-accordion:has([data-te-collapse-show]) {
  @apply md:mb-5;
}

@media (max-width: 767px) {
  .filtersMob {
    @apply fixed left-0 w-full h-full bg-white;
    top: 3.75rem;
    z-index: 9999;
    max-height: calc(100dvh - 3.75rem);
    transform: translateX(100%);
    transition: all 0.3s;
    &.activeFIlter {
      transform: translateX(0);
    }
  }
}
body.kycActive {
  padding-right: 0 !important;
  overflow: hidden;
}

.react-pdf__Page.loadingPage {
  display: none;
}

.gray-bg-label {
  label {
    &:before {
      background-color: #f8f8f8;
    }
  }
}

.full-dropdown {
  .dropdown {
    &-main-flex {
      flex: 1;
    }
    &_wrap {
      width: 100%;
      max-width: 100%;
    }
  }
}

.add-card-country-drop {
  .all_dropdown__control {
    background-color: rgb(242 242 242 / var(--tw-bg-opacity));
    border-color: rgb(242 242 242 / var(--tw-bg-opacity));
  }
  .all_dropdown__placeholder,
  .all_dropdown__single-value {
    font-size: 0.8125rem !important;
  }
}
#responseviewdiv {
  position: absolute;
  bottom: 0;
  left: 4px;
  width: 99.5%;
  max-height: 80%;
  padding: 5px;
  background-color: white;
  z-index: 9;
  overflow: auto;
  resize: none;
}

.resizer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 40%;
  padding: 3px;
  background: transparent;
  z-index: 9999999;
  overflow: auto;
  cursor: n-resize;
  transition: background-color 0.2s;
}
.intercom-lightweight-app-launcher,
.intercom-with-namespace-52k34s {
  position: absolute;
  top: 0.375rem !important;
  @media (min-width: 768px) {
    right: 11.25rem !important;
  }
}
.intercom-messenger-frame {
  top: 1.5rem !important;
}

.filtersMob {
  .table_dropdown__single-value,
  .table_dropdown__placeholder,
  .table_multiDropdown__placeholder,
  .table_multiDropdown__value-container .option-label {
    font-size: 0.75rem !important;
  }
  .multi-select-dropdown .table_multiDropdown__control {
    height: 1.875rem !important;
  }
  .table_multiDropdown__indicators {
    margin-top: 0.725rem;
  }
}

.resizer:hover {
  box-shadow: 0 1.3px 1px rgba(0, 0, 0, 0.2);
}

/* API connector method colors */
.method-get {
  color: #008000;
}

.method-post {
  color: #d48900;
}

.method-put {
  color: #0000ff;
}

.method-patch {
  color: #800080;
}

.method-delete {
  color: #8b0000;
}

.method-head {
  color: #008000;
}

.method-options {
  color: #b03060; /* Maroon */
}
.overflow-hidden-custom {
  overflow: hidden;
}
/***********View list style******/
.cloneView {
  .column-view {
    .third-col {
      padding-left: 0.375rem;
    }
    .four-col {
      padding-left: 0.375rem;
    }

    .five-col {
      &:not(.disabled) {
        padding-left: 0.375rem;
        @apply text-violet-800;
      }
    }
    .eight-col,
    .nine-col,
    .ten-col,
    .eleven-col,
    .twelve-col,
    .thirteen-col,
    .fourteen-col,
    .fifteen-col,
    .sixteen-col,
    .twentyone-col,
    .twentytwo-col {
      padding-left: 0.375rem;
    }
    .seventeen-col {
      &:before {
        display: none;
      }
      .priceInnerView {
        &::before {
          content: attr(data-sybmol-attribute);
          font-size: 0.875rem;
          padding-left: 0.6875rem;
          padding-right: 1.0625rem;
          line-height: 1.125rem;
          top: 0.0313rem;
          position: relative;
        }
      }
    }
    .eighteen-col {
      &:before {
        display: none;
      }
      .priceInnerView {
        width: 100%;
        &::before {
          content: attr(data-sybmol-attribute);
          font-size: 0.875rem;
          padding-left: 0.6875rem;
          padding-right: 1.0625rem;
          line-height: 1.125rem;
          top: 0.0313rem;
          position: relative;
        }
      }
    }
    .DropdownInnerView,
    .InputInnerView {
      border-radius: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0.25rem 0.3125rem 0.25rem calc(0.5rem + 2px);
      min-height: 1.5rem;
      max-width: 100%;
      background-color: rgb(242 242 242/1);
    }
    &.-selected {
      .priceInnerView {
        span {
          border-color: #fff !important;
        }
      }
    }
    .priceInnerView {
      display: flex;
      align-items: center;
      min-height: 1.5rem;
      border-radius: 0.25rem;
      background-color: rgb(242 242 242/1);
      &:before {
        min-width: 1.87rem;
        padding: 0 !important;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
      }
      span {
        width: 100%;
        border-left: 1px solid #fff !important;
        border-color: #fff !important;
        min-height: 1.5rem;
        padding: 0.125rem 0.4375rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .edited {
      .DropdownInnerView,
      .InputInnerView,
      .priceInnerView {
        background: #e4e3ff;
      }
    }
  }
  .PPEViewFields {
    .singleMKTPrice {
      .seventeen-col {
        @apply w-full border rounded bg-gray-100 border-gray-100;
        max-width: 110px;
        &:before {
          @apply h-full border-r flex items-center justify-center p-0 border-white;
          width: 1.875rem;
        }
        span {
          @apply overflow-hidden text-ellipsis whitespace-nowrap w-full block;
          max-width: 5rem;
          padding: 3px 7px;
        }
      }
      &.edited {
        .seventeen-col {
          background: #e4e3ff;
          border-color: #e4e3ff;
          &::before {
            @apply border-white;
          }
        }
      }
    }
  }
}
.addInventoryView {
  .column-view {
    .fifteen-col,
    .eleven-col,
    .sixteen-col {
      min-width: 6.25rem !important;
      max-width: 6.25rem !important;
    }
  }
}
#DivHead {
  pointer-events: none;
  button {
    pointer-events: auto;
  }
}
.intercom-app {
  .intercom-messenger-frame {
    @media (max-width: 767px) {
      @apply bg-violet-500;
      top: 0 !important;
      padding-top: 3.8125rem;
      iframe {
        height: calc(100dvh - 3.8125rem) !important;
      }
    }
  }
}

.mobileRow {
  .column-view {
    .second-col,
    .third-col,
    .four-col,
    .five-col,
    .six-col,
    .seven-col,
    .eight-col,
    .nine-col,
    .ten-col,
    .eleven-col,
    .twelve-col,
    .thirteen-col,
    .fourteen-col,
    .fifteen-col,
    .sixteen-col,
    .twentyone-col,
    .twentytwo-col,
    .twenty-col {
      padding-left: 0.375rem;
    }
    .second-col {
      padding-left: 0.9375rem;
    }
    .seventeen-col,
    .eighteen-col {
      &:before {
        padding-left: 0;
        padding-right: 0.375rem;
      }
      &.nosymbol {
        &:before {
          display: none;
        }
      }
    }
    .twentyone-col,
    .twentytwo-col {
      min-width: 8.75rem;
    }
  }
}

#add-inventory-mobile-filter,
.editRow {
  .table_dropdown__input-container,
  .table_multiDropdown__input-container {
    input {
      font-size: 0.75rem !important;
      line-height: 1.5rem !important;
    }
  }
}

.card_options {
  .whiteFormFields .all_dropdown__single-value {
    font-weight: 500 !important;
    font-size: 0.8125rem !important;
  }
}
.intercom-lightweight-app {
  z-index: 1046 !important;
  .intercom-lightweight-app-launcher {
    bottom: 1.25rem;
    right: 1.25rem;
    max-width: 3rem;
    width: 3rem;
    max-height: 3rem;
    height: 3rem;
  }
  .intercom-lightweight-app-launcher-icon-open {
    width: 3rem;
    height: 3rem;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.singleMKTPrice {
  &.disable {
    .proceed-price {
      @apply pointer-events-none;
      .dropdown {
        @apply shadow-none;
      }
      .price_field {
        @apply border-gray-100 bg-gray-100;
      }
      input {
        @apply bg-gray-100 border-gray-100 border-l-white;
      }
    }
  }
  .priceFieldPPE {
    &.disable {
      .price_field,
      input {
        @apply text-gray-400;
      }
    }
    .dropdown {
      @media (min-width: 768px) {
        max-width: 6.875rem;
      }
      input:focus {
        color: inherit !important;
      }
    }
    .price_suggation {
      @apply rounded;
    }
  }
  &.edited {
    .priceFieldPPE {
      .dropdown {
        box-shadow: none;
      }

      .price_field,
      input {
        background-color: #e4e3ff;
        border-color: transparent !important;
      }
      input {
        @apply border-r border-white;
        border-left: 1px solid #fff !important;
      }
    }
  }
}
.addinvPPE {
  .dropdown {
    @media (min-width: 768px) {
      max-width: inherit !important;
    }
    .price_suggation {
      padding-left: calc(100% + 0.75rem);
      width: 23.125rem;
    }
  }
}
.proceed-price,
.priceMobField {
  &.disable {
    .dropdown {
      @apply shadow-none;
    }

    .price_field,
    input {
      @apply text-gray-400 border-gray-100 bg-gray-100 #{!important};
      + label {
        @apply text-gray-400 #{!important};
      }
    }
    input {
      @apply border-l-white #{!important};
    }
  }
}
.priceDis {
  input:disabled {
    @apply rounded-l-none border-l-white;
  }
}

.disableField {
  .dropdown {
    box-shadow: none;
    .price_field {
    }
    input {
      @apply border-gray-100 border-l-white;
      // border-left: 1px solid #fff;
    }
  }
}

.PPEViewFields {
  @apply flex border-b;
  .PPEFieldView {
    @apply py-2 px-[.9375rem] gap-x-2.5 flex sticky left-10;
  }
  .PPEToggle {
    @apply flex items-center px-3 py-5;
    min-width: 6.3537rem;
  }
  .singleMKTPrice {
    padding: 0.4375rem;
    padding-top: 0.1875rem;
    min-width: 7.875rem;
    border: 1px solid transparent;
    .label {
      @apply flex;
      line-height: 0.9375rem;
      margin-bottom: 0.3125rem;
    }
    .seventeen-col {
      @apply flex items-center h-6;

      &::before {
        // padding-top: 0.5rem;
        content: attr(data-sybmol-attribute);
        // padding-top: 0.375rem;
        font-size: 0.875rem;
        padding-left: 0.6875rem;
        padding-right: 1.125rem;
        line-height: 1.125rem;
        top: 0;
        position: relative;
      }
    }
  }
}

.refund-amount-wrapper {
  a.pointer-events-none {
    @apply border-solid border-gray-300 border-r-0 #{!important};
  }
  .error-field {
    a.pointer-events-none {
      @apply border-rose-500 #{!important};
    }
  }
}

.form_multiDropDown__menu {
  .form_multiDropDown__group-heading {
    @apply max-w-full overflow-hidden text-ellipsis;
  }
}
.all_dropdown__menu-portal {
  z-index: 9999 !important;
}
.all_dropdown__option {
  > div:first-child {
    @apply max-w-full overflow-hidden text-ellipsis;
  }
}

.seller_level_drop_down .all_dropdown__placeholder {
  color: #808080;
}

.priceDisoffer {
  .priceDis {
    input {
      border: none !important;
      border-left: 1px solid #fff !important;
    }
  }
}
.have_original_value .all_dropdown__control {
  @apply bg-indigo-50;
}
@media (min-width: 1200px) {
  .menuActive {
    + .main-container {
      margin-left: 13.9rem;
    }
  }
}
.gtvprogress {
  &:before {
    width: var(--progress-width);
    background-color: var(--progress-color);
  }
  &.radius {
    &:before {
      border-radius: 4px;
    }
  }
}

.targetgtv {
  left: var(--targetgtv);
}
.svg-stadium {
  text {
    @apply font-inter #{!important};

    &.stand-label,
    &.tier-label {
      @apply font-inter_semi #{!important};
    }
  }
}

.intercom-namespace .intercom-app > div,
.intercom-namespace .intercom-app .edrs4yi0,
.intercom-namespace .intercom-app .ed8ycz90 {
  z-index: 1046;
}

.swal2-popup.swal2-toast .swal2-close,
.swal2-popup.swal2-toast .swal2-icon {
  align-self: start !important;
  margin: 0.5em 0 !important;
}
.triangle-up {
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 16px solid rgba(255, 106, 0, 0.7);
  right: -11px;
  z-index: 0;
  bottom: -3px;
  position: absolute;
}

.numberList {
  ol {
    counter-reset: item;
    margin-left: 0;
    padding-left: 0;
    li {
      display: block;
      padding-left: 0.9375rem;
      position: relative;
      font-size: 0.75rem;
      &::before {
        position: absolute;
        left: 0;
        top: 0;
        content: counter(item) ".";
        counter-increment: item;
      }
    }
  }
}

.singleFOrmBLock,
.panelty-note,
.panelty-input {
  &.edited {
    .all_dropdown__control,
    .form_multiDropDown__control,
    .price_field,
    .dropdown input,
    .table-date-icon,
    .picker-input {
      @apply border-indigo-500 #{!important};
    }
  }
}
.panelty-input {
  &.edited {
    input {
      @apply border-indigo-500 #{!important};
    }
  }
}

.leftDisable {
  .checkbox_td:after {
    box-shadow: none;
    background: none;
  }
}
.rightDisable {
  .left-shad:before {
    box-shadow: none;
    background: none;
  }
}

.variation-virtuoso {
  div[data-viewport-type="element"] {
    @apply pt-5;
  }
  div[data-test-id="virtuoso-item-list"] {
    > div {
      @apply px-5;
    }
  }
}

.single_order {
  tr {
    td {
      &:nth-last-child(2) {
        padding-right: 85px;
      }
    }
  }
}

.section-error > .inventory_select__control {
  @apply border border-rose-500 #{!important};
}

.buy-block-wrap {
  .right-buy {
    .price-detail {
      .all_dropdown__control {
        min-width: 5.6875rem;
        max-width: 5.6875rem;
      }
    }
  }
}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 16px solid rgba(255, 106, 0, 0.7);
  right: -11px;
  z-index: 0;
  bottom: -3px;
  position: absolute;
}

.addInv_fil {
  .all_dropdown__control,
  .form_multiDropDown__control {
    min-width: unset !important;
  }
}

// .payout-table > div {
//   > div {
//     --scrollbar-thumb: rgba(191, 191, 191, 0.5) !important;
//     @apply scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full #{!important};
//   }
// }

.apply-filter-common {
  height: 1.875rem !important;
}
